import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './App.css';
import Login from './Login';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [file, setFile] = useState(null);
  const [clientName, setClientName] = useState('');
  const [meetingDate, setMeetingDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transcripts, setTranscripts] = useState([]);
  const [selectedTranscript, setSelectedTranscript] = useState(null);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    if (!token) return;  // Only fetch transcripts if token exists

    const fetchTranscripts = async () => {
      try {
        const res = await axios.get('https://firefly-backend.pristio.com/transcripts', {
          headers: { Authorization: `Bearer ${token}` }  // Include the JWT token here
        });
        setTranscripts(res.data);
      } catch (err) {
        console.error('Error fetching transcripts', err);
      }
    };
    
    fetchTranscripts();
  }, [token]);  // Depend on 'token' so this runs when token is set
  
  if (!token) {
    return <Login setToken={setToken} />;
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('clientName', clientName);
    formData.append('meetingDate', meetingDate);

    try {
      const res = await axios.post('http://localhost:8080/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`  // Include the JWT token here
        }
      });
      console.log('Response:', res.data); 
      setSelectedTranscript(res.data); // Set the uploaded transcript as selected
    } catch (err) {
      setError('An error occurred while processing your request. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleTranscriptClick = (transcript) => {
    setSelectedTranscript(transcript);
  };

  const handleDeleteTranscript = async (id) => {
    try {
      await axios.delete(`http://localhost:8080/transcripts/${id}`, {
        headers: { Authorization: `Bearer ${token}` }  // Include the JWT token here
      });
      setTranscripts(transcripts.filter(transcript => transcript.id !== id));
    } catch (err) {
      console.error('Error deleting transcript', err);
    }
  };

  const handleQuestionSubmit = async () => {
    try {
      const res = await axios.post('http://localhost:8080/ask_question', {
        question: question,
        transcript: selectedTranscript.transcript,
      }, {
        headers: { Authorization: `Bearer ${token}` }  // Include the JWT token here
      });
      setAnswer(res.data.answer);
    } catch (err) {
      setAnswer('An error occurred while processing your question.');
      console.error(err);
    }
  };

  return (
    <div className="App">
      <div className="sidebar">
        <h2>Clients & Meetings</h2>
        <ul>
          {transcripts.map((transcript) => (
            <li key={transcript.id}>
              <span onClick={() => handleTranscriptClick(transcript)}>
                {transcript.client_name} - {transcript.meeting_date}
              </span>
              <button 
                onClick={() => handleDeleteTranscript(transcript.id)} 
                className="delete-button"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="container">
        <h1 className="title">Audio Transcription Service</h1>
        <p className="subtitle">Easily upload your audio files and receive a detailed transcription and analysis report.</p>
        
        <form onSubmit={handleSubmit} className="upload-form">
          <div className="form-group">
            <label>Client Name</label>
            <input 
              type="text" 
              value={clientName} 
              onChange={(e) => setClientName(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label>Meeting Date</label>
            <input 
              type="date" 
              value={meetingDate} 
              onChange={(e) => setMeetingDate(e.target.value)} 
              required 
            />
          </div>
          <div className="form-group">
            <label>Upload Audio File</label>
            <input 
              type="file" 
              onChange={handleFileChange} 
              required 
            />
          </div>
          <button type="submit" className="upload-button" disabled={loading}>
            {loading ? 'Processing...' : 'Upload'}
          </button>
        </form>

        {loading && <div className="loading-spinner">⏳ Processing your upload...</div>}
        {error && <div className="error-message">{error}</div>}

        {selectedTranscript && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setSelectedTranscript(null)}>&times;</span>
              <h2>Transcription and Analysis Report</h2>
              <div className="modal-body">
                <p><strong>Client Name:</strong> {selectedTranscript.client_name}</p>
                <p><strong>Meeting Date:</strong> {selectedTranscript.meeting_date}</p>
                <p><strong>Transcription:</strong></p>
                <p>{selectedTranscript.transcript || "No transcription available."}</p>
                <div className="report">
                  <p><strong>Summary:</strong></p>
                  <ReactMarkdown>{selectedTranscript.report.summary}</ReactMarkdown>

                  <p><strong>Sentiment:</strong></p>
                  <ReactMarkdown>{selectedTranscript.report.sentiment}</ReactMarkdown>

                  <p><strong>Tasks:</strong></p>
                  <ReactMarkdown>{selectedTranscript.report.tasks}</ReactMarkdown>

                  <p><strong>Outline:</strong></p>
                  <ReactMarkdown>{selectedTranscript.report.outline}</ReactMarkdown>

                  <p><strong>Notes:</strong></p>
                  <ReactMarkdown>{selectedTranscript.report.notes}</ReactMarkdown>
                </div>

                {/* Question box and display */}
                <div className="question-box">
                  <h3>Ask a Question about the Transcript</h3>
                  <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Enter your question here"
                    className="question-input"
                  />
                  <button onClick={handleQuestionSubmit} className="question-button">
                    Submit
                  </button>
                </div>

                {answer && (
                  <div className="answer-box">
                    <h4>Answer:</h4>
                    <ReactMarkdown>{answer}</ReactMarkdown>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
